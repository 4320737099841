<template>
  <div class="d-flex mt-24">
    <div>
      <InfoIcon class="mr-8" height="24" />
    </div>
    <div>
      <a v-if="href" :href="href" target="_blank" class="text-decoration-none">
        {{ text }}
      </a>
      <span v-else>
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
import InfoIcon from '~/assets/icons/info.svg'

export default {
  components: {
    InfoIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
span {
  color: $green-300;
}
</style>
